<template>
    <b-modal
      v-model="data.showModal"
      button-size="sm"
      modal-class="sample-modal"
      title-class="w-100"
      footer-class="w-100"
      no-fade
      @show="onShow"
      size="md"
      :no-close-on-backdrop="true"
      scrollable
    >
      <!-- Modal header -->
      <div slot="modal-title">
        <h1 class="flex gap-2 items-center text-nowrap">
          <div class="text-xl font-bold">Test</div>
          <div
            v-if="sample && sample.sample_number.length > 0"
            class="text-xl"
          >
            - {{ sample.sample_number }}
          </div>
        </h1>
      </div>

      <!-- Modal content -->
      <div v-if="sample" class="flex flex-column gap-4">
        <table class="table-sample">
          <tbody>
            <tr>
              <td class="row-fit text-nowrap">
                <span class="mr-2">Sample Number</span>
              </td>
              <td>
                <b-form-input
                  v-model="sample.sample_number"
                  autocomplete="off"
                  size="sm"
                  trim
                  autofocus
                ></b-form-input>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="d-flex flex-col gap-4">
          <sample-cycle
            v-for="cycle in cycles"
            :key="`cycle_${cycle.cycle_index}`"
            :sample="sample"
            :cycle="cycle"
            :lastCycle="lastCycle"
            :periodIssues="periodIssues"
            :periods="periods"
            :issues="issues"
            @delete="deleteCycle"
          />
        </div>

        <div v-if="canStartCycle">
          <b-btn
            variant="primary"
            size="sm"
            @click="startCycle()"
          >
            Start next cycle
          </b-btn>
        </div>

        <div class="d-flex items-center gap-2">
          <div>Note</div>
          <b-form-input
            v-model="sample.sample_note"
            autocomplete="off"
            size="sm"
            trim
          ></b-form-input>
        </div>
      </div>
      <div v-else-if="isLoading">
        Loading..
      </div>

      <!-- Modal footer -->
      <div v-if="sample" slot="modal-footer" class="w-100 flex justify-between gap-4 items-center">
        <div class="d-flex gap-2">
          <b-btn
            variant="primary"
            size="sm"
            @click="saveIssue()"
            :disabled="!canSave"
          >
            Save
          </b-btn>

          <b-btn
            variant="primary"
            size="sm"
            @click="saveIssue(true)"
            :disabled="!canSave"
          >
            Save & print
          </b-btn>
        </div>
        <div>
          <a
            v-if="sample.sample_cycles !== 4"
            href="#"
            class="text-primary"
            @click.prevent="restoreSample"
          >
            Continue test (Cycle {{ sample.sample_cycles + 1 }})
          </a>
          <a
            v-else-if="canEnd"
            href="#"
            class="text-primary"
            @click.prevent="endSample"
          >
            End test early (Cycle {{ cycles.length }})
          </a>
        </div>
      </div>
    </b-modal>
  </template>

<script>
import moment from 'moment';

const SampleCycle = () => import('@/components/burn_samples/SampleCycle.vue');

export default {
  name: 'SampleModal',
  components: {
    SampleCycle,
  },
  props: {
    data: Object,
    periods: Array,
    issues: Array,
  },
  computed: {
    changes() {
      return {
        sample_number: this.sample.sample_number,
        sample_note: this.sample.sample_note,
        sample_cycles: this.sample.sample_cycles,
        cycles: this.cycles.map((cycle) => ({
          ...cycle,
          states: cycle.states.filter((state) => state.issue_id !== null),
        })),
      };
    },
    isLoading() {
      return this.loadingCount > 0;
    },
    canPrint() {
      if (!this.lastCycle || !this.lastState) {
        return false;
      }
      const isFailure = this.lastState.issue_id !== 1;
      if (isFailure) {
        return true;
      }
      const lastPeriodId = this.lastCycle.cycle_end_period ?? 6;
      const isFinalCycle = this.lastCycle.cycle_index === this.sample.sample_cycles;
      const isFinalStateOkay = (
        this.lastState.period_id === lastPeriodId
        && this.lastState.issue_id === 1
      );
      const isSuccess = isFinalCycle && isFinalStateOkay;
      if (isSuccess) {
        return true;
      }
      return false;
    },
    canSave() {
      if (!this.sample) return true;
      if (this.sample.sample_number.length === 0) return false;
      const cyclesOkay = this.cycles.every((cycle) => {
        if (cycle.cycle_start_weight.length === 0) return false;
        return true;
      });
      if (!cyclesOkay) return false;
      return true;
    },
    canEnd() {
      if (this.cycles.length === 0) return false;
      if (this.cycles.length === 4) return false;
      return true;
    },
    canStartCycle() {
      if (this.cycles.length === 0) return true;
      if (this.cycles.length >= this.sample.sample_cycles) return false;
      const lastCycle = this.cycles[this.cycles.length - 1];
      const lastCycleHasEndWeight = lastCycle.cycle_end_weight.length > 0;
      if (!lastCycleHasEndWeight) return false;
      const lastPeriodId = (
        lastCycle.cycle_end_period
        ?? (lastCycle.cycle_index === this.sample.sample_cycles ? 6 : 5)
      );
      const lastState = lastCycle.states.find((state) => state.period_id === lastPeriodId);
      const lastStateHasIssue = lastState.issue_id === null || lastState.issue_id !== 1;
      if (lastStateHasIssue) return false;
      return true;
    },
    lastCycle() {
      return this.cycles.length > 0 ? this.cycles[this.cycles.length - 1] : null;
    },
    lastState() {
      return (
        this.lastCycle
          ? this.lastCycle.states.findLast((cycle) => cycle.issue_id !== null)
          : null
      );
    },
  },
  data() {
    return {
      loadingCount: 0,
      sample: null,
      cycles: [],
      periodIssues: {},
    };
  },
  methods: {
    onShow() {
      this.fetchSample();
      this.buildPeriodIssues();
    },
    onClose() {
      this.data.showModal = false;
      this.$emit('close');
    },
    buildPeriodIssues() {
      const periodIssues = {};
      this.periods.forEach((period) => {
        periodIssues[period.id] = [{
          id: null,
          issue_description: '',
          issue_prefix: '',
          issue_suffix: '',
          issue_placeholder: '',
          issue_has_value: false,
        }];
      });
      this.issues.forEach((issue) => {
        issue.issue_periods.forEach((issuePeriod) => {
          periodIssues[issuePeriod].push({
            id: issue.id,
            issue_description: issue.issue_description,
            issue_prefix: issue.issue_prefix,
            issue_suffix: issue.issue_suffix,
            issue_placeholder: issue.issue_placeholder,
            issue_has_value: issue.issue_has_value,
          });
        });
      });
      this.periodIssues = periodIssues;
    },
    buildCycles() {
      this.cycles = [];
      this.sample.cycles.forEach((cycle) => {
        const row = {
          id: cycle.id,
          cycle_index: cycle.cycle_index,
          cycle_date: cycle.cycle_date,
          cycle_start_weight: cycle.cycle_start_weight,
          cycle_end_weight: cycle.cycle_end_weight,
          cycle_end_period: cycle.cycle_end_period,
          states: [],
        };
        this.periods.forEach((period) => {
          const state = cycle.states.find((item) => item.period_id === period.id);
          row.states.push({
            id: state ? state.id : null,
            period_id: period.id,
            period_description: period.period_description,
            issue_id: state ? state.issue_id : null,
            issue_value: state ? state.issue_value : '',
          });
        });
        this.cycles.push(row);
      });
    },
    prepareCycles() {
      this.buildCycles();
      if (this.cycles.length === 0) {
        this.startCycle();
      }
    },
    startCycle() {
      const lastCycle = this.cycles.length === 0 ? null : this.cycles[this.cycles.length - 1];
      const cycleIndex = lastCycle ? lastCycle.cycle_index + 1 : 1;
      const row = {
        id: null,
        cycle_index: cycleIndex,
        cycle_date: moment().format('YYYY-MM-DD HH:mm:ss'),
        cycle_start_weight: lastCycle ? lastCycle.cycle_end_weight : '',
        cycle_end_weight: '',
        cycle_end_period: null,
        states: [],
      };
      this.periods.forEach((period) => {
        row.states.push({
          id: null,
          period_id: period.id,
          period_description: period.period_description,
          issue_id: null,
          issue_value: '',
        });
      });
      this.cycles.push(row);
    },
    deleteCycle(cycle) {
      if (!confirm(`Delete #${cycle.cycle_index} cycle?`)) return;
      this.cycles = this.cycles.filter((item) => item.cycle_index !== cycle.cycle_index);
    },
    handleCycleChanges() {
      this.cycles.forEach((cycle) => {
        const firstEmptyStateIndex = cycle.states.findIndex((state) => state.issue_id === null);
        if (firstEmptyStateIndex === -1) {
          return;
        }
        for (let i = firstEmptyStateIndex; i < cycle.states.length; i++) {
          cycle.states[i].issue_id = null;
          cycle.states[i].issue_value = '';
        }
      });
    },
    fetchSample() {
      this.loadingCount++;
      this.$http
        .get(`/burn_sample/sample/${this.data.sample.id}`)
        .then((res) => {
          this.sample = res.body.sample;
          this.prepareCycles();
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch sample: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    restoreSample() {
      this.sample.sample_cycles = 4;
    },
    endSample() {
      this.sample.sample_cycles = this.cycles.length;
    },
    saveIssue(printLabel) {
      printLabel = printLabel === undefined ? false : printLabel;
      this.loadingCount++;
      this.$http
        .put(`/burn_sample/sample/${this.data.sample.id}`)
        .send({ changes: this.changes })
        .send({ printLabel })
        .then(() => {
          this.$emit('change', this.data.sample.id);
          this.onClose();
        })
        .catch((err) => {
          this.$toast.error(`Failed to update sample: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
  },
  watch: {
    cycles: {
      handler() {
        this.handleCycleChanges();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  .table-invoice {
    tr {
      border-top: 5px solid transparent;
    }

    tr > td:first-child {
      padding-right: 10px;
    }
  }
</style>
